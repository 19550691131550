<template>

<div class="scan">

	<com-stream v-on:init="onInit" v-on:decode="onDecoded" />

	<app-icon icon="loading" class="scan-loading" v-if="is.loading" />

	<div class="scan-close" v-on:click="$emit('close')" v-if="allowClose"><app-icon icon="close" /></div>

	<div class="scan-manual" :class="{'is-checking': checking, 'is-failed': failed}" v-if="!is.loading && allowManual">

		<input v-model="manual" v-on:keyup.enter="onManualConfirm" maxlength="32" class="scan-manual-input" placeholder="manually enter id number and press enter" type="tel" />

	</div>
	
</div>

</template>

<script>

import {QrcodeStream} from 'vue-qrcode-reader'

export default {

	props: ['allowClose', 'allowManual', 'failed', 'checking'],

	components: {
		'com-stream': QrcodeStream,
	},

	data: function() {

		return {
			manual: '',
			is: {
				loading: true
			}
		}

	},

	methods: {

		onManualConfirm: function() {

			this.$emit('scanned', this.manual)

		},

		onDecoded: function(value) {

			this.$emit('scanned', this.$util.scanClean(value))

			this.$emit('close')

		},

		onInit: function(promise) {

			promise.then(function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.scan {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #fff;
	z-index: 10000;
}

.scan-close {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 2;
	width: 40px;
	height: 40px;
	font-size: 24px;
	cursor: pointer;
	color: #fff;
	text-align: center;
	line-height: 42px;
	border-radius: 50%;
	background-color: #2f3a4a;
}

.scan-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

.scan-manual {
	position: absolute;
	left: 10px;
	bottom: 10px;
	width: calc(100% - 20px);
	height: 40px;
	border-radius: 4px;
	display: flex;
	background-color: rgba(0, 0, 0, 0.75);
}

.scan-manual.is-failed {
	background-color: #c13c3c;
}

.scan-manual.is-checking {
	pointer-events: none;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url("data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA")
}

.scan-manual.is-checking .scan-manual-input {
	opacity: 0;
}

.scan-manual-input {
	height: 40px;
	flex-grow: 1;
	font-size: 16px;
	text-align: center;
	color: #fff;
	transition: opacity 100ms linear;
	font-weight: 600;
}

.scan-manual-input::placeholder {
	color: rgba(255, 255, 255, 0.5);
	font-weight: 300;
}

.scan-manual-submit {
	color: #fff;
}

</style>
